<template>
    <div :class="Skin" @click="goClick">
      <img :src=BannerImg alt="dashboard-featured-img"/>
      <div class="img-text">
        <p>{{ BannerType }}</p>
        <p>{{ BannerTitle }}</p>
      </div>
    </div>
</template>
<script>
import {reactive, toRefs} from '@vue/composition-api'

export default {
  components: {},
  props: {
    skin: {type: String, default: ''},
    bannerTitle: {type: String, default: ''},
    bannerType: {type: String, default: ''},
    bannerImg: {type: String, default: ''},
  },
  setup(props) {
    const state = reactive({
      Skin: props.skin,
      BannerTitle: props.bannerTitle,
      BannerType: props.bannerType,
      BannerImg: props.bannerImg,
    })
    return toRefs(state)
  },
  methods: {
    goClick() {
      this.$emit('click')
    },
  },
}
</script>
