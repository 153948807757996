<template>
  <div class="dashboard">
    <!--  your songs section-->
    <section class="your-songs-section">
      <p>YOUR SONGS</p>
      <div class="your-songs-box">
        <v-icon class="no-songs-icon" size="100">mdi-music</v-icon>
        <p>NO SONGS YET</p>
        <P>Collect songs to access streaming history.</P>
      </div>
    </section>

    <!--  dashboard featured section-->
    <section class="dashboard-featured-section">
      <p>FEATURED</p>
      <banner
          skin="dashboard-featured-img"
          bannerTitle="Ultra Black"
          bannerType="SINGLE"
          bannerImg="https://royal-io.imgix.net/4b7ddb24-6831-4fd0-af74-3d0d316a4b15.png"
          @click="goPage('edition')"/>
<!--      <banner-->
<!--          skin="dashboard-featured-img"-->
<!--          :bannerTitle="featuredEdition.name"-->
<!--          :bannerType="featuredEdition.type"-->
<!--          :bannerImg="`https://royal-io.imgix.net/${featuredEdition.filename}`"-->
<!--          @click="goPage('edition')"/>-->
    </section>
  </div>
</template>

<script>
import Banner from "@/components/Banner/Banner";
import axios from "axios";

export default {
  name: 'Dashboard',
  components: {
    Banner,
  },
  data() {
    return {
      data: [],
      featuredEdition: {},
      isLoading: true,
      events: [],
    }
  },
  created() {
    axios.get("//13.125.234.124:3002/dashboard").then(({ data }) => {
    }).catch
    ((err) => {
      this.$router.push({name: 'auth'})
    });
  },
  async mounted() {
    await this.dashboardData()
  },
  methods: {
    async dashboardData() {
      await this.$http.get('/dashboards'
      ).then(res => {
        this.data = res.data.data
        this.featuredEdition = res.data.featuredEdition
        console.log(this.featuredEdition)
      }).catch
      ((err) => {
        console.log(err)
      })
    },
    goPage(name) {
      this.$router.push({
        name: name,
        params: {
          edition: "Ultra Black"
        }
      })
    }
  }
}
</script>